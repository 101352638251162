/* WeatherMap.css */
html, body {
  height: 100%;
  margin: 0;
}

#map {
  background-color: #363942; /* Tamno siva boja */
}

.leaflet-container {
  height: 400px;
  width: 600px;
  max-width: 100%;
  max-height: 100%;
}

.leaflet-tile {
  transition: opacity 0.3s ease-in-out;
}

.dark-tiles {
  background-color: #000000; /* Tamno siva boja */
}

.leaflet-popup-content {
  color: white; /* Bela boja teksta */
  font-size: 16px; /* Veći font */
}

.my-custom-icon div {
  background-color: white;
  color: white;
  padding: 2px;
  font-size: 12px;
  border-radius: 50%;
  width: 2px;
  height: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-marker-container {
  position: relative;
  text-align: center;
}

.custom-marker-icon {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
  padding: 2px;
  font-size: 12px;
  border-radius: 50%;
  width: 0.5px;
  height: 0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-marker-label {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: white;
}

.custom-marker-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.small-dot {
  width: 4px; /* Size of the small dot */
  height: 4px;
  background-color: white;
  border-radius: 50%;
  margin-bottom: 2px; /* Space between dot and city name */
}

.city-name {
  color: white;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
}

.hour-slider {
  width: 90%;
  max-width: 400px;
}

.slider {
  width: 100%;
  appearance: none;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #4caf50;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #4caf50;
}

.slider-value {
  color: white;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.5);
  padding: 2px 5px;
  border-radius: 5px;
}

body {
  padding: 0;
  margin: 0;
}

#map {
  height: 100%;
  width: 100vw;
}

